import React from 'react'
import '../css/intro.css'

class Intro extends React.Component {
  render(){
    return (
        <div className="Intro Wrapper">

        <article className="CTA" id="About">

          <h2>Hello</h2>

          <p>Im <strong>Corbin</strong>, a Computer Science student</p>
          <p>I enjoy creating beautiful things with code</p>
          <p>that <strong>help people</strong>, and <strong>make an impact.</strong></p>


        </article>

      </div>
    )
  }
}

export default Intro
