import React from 'react'
import Nav from './Nav'

import '../css/style.css'

class Footer extends React.Component {
  render(){
    return (
      <footer>
        <Nav id="Contact" />
      </footer>
    )
  }
}

export default Footer
